// components/InviteLink.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../contexts/UserContext";

const InviteLink = () => {
  const [copied, setCopied] = useState(false);

  const { userId, userName } = useUserContext();
  const inviteLink = `https://cavegram.com/${userName}/?r=${userId}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="invite-link-container">
      <input
        type="text"
        value={inviteLink}
        readOnly
        className="invite-link-input"
      />
      <button onClick={handleCopy} className="copy-button">
        <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
        {copied ? "Copied" : "Copy"}
      </button>
    </div>
  );
};

export default InviteLink;
