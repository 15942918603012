import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import CustomContextMenu from "./CustomContextMenu";
import { useUserContext } from "../contexts/UserContext";

const DrawReaction = ({
  doodle,
  handleOpenDoodleEditor,
  reactionsButtonDisabled,
}) => {
  const { ShouldShowSignUpPrompt } = useUserContext();

  const openDoodleEditor = (
    actionType,
    doodle_id,
    draw_over_doodle_id = null
  ) => {
    if (ShouldShowSignUpPrompt()) return; // Check for authentication
    handleOpenDoodleEditor(actionType, doodle_id, draw_over_doodle_id);
  };

  return (
    <CustomContextMenu
      triggerElement={
        <button
          className={`button ${
            reactionsButtonDisabled ? "inactive" : "active"
          }`}
          disabled={reactionsButtonDisabled}
          aria-label="React to doodle"
        >
          <FontAwesomeIcon icon={faComment} className="icon" />
          <span className="counter">{doodle.reactions_counter}</span>
        </button>
      }
    >
      <div
        className="menu-item"
        onClick={() => openDoodleEditor("postReaction", doodle.id)}
      >
        New drawing
      </div>
      <div
        className="menu-item"
        onClick={() =>
          openDoodleEditor("postReaction", doodle.id, doodle.id)
        }
      >
        Draw over
      </div>
    </CustomContextMenu>
  );
};

export default DrawReaction;
