// SelectAvatar.js

import React, { useContext } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { useDoodleContext } from "../contexts/DoodleContext";
import { useUserContext } from "../contexts/UserContext";

const SelectAvatar = ({ doodle }) => {
  const axiosInstance = useContext(AxiosContext);
  const { setIsProfileEntriesFeedOpen } = useDoodleContext();
  const { setUserAvatar } = useUserContext();

  const handleSelectAvatar = async () => {
    try {
      await axiosInstance.post(`/doodles/${doodle.id}/select-avatar/`);
      setIsProfileEntriesFeedOpen(false);
      setUserAvatar(doodle);
    } catch (error) {
      console.error("Error selecting avatar:", error);
    }
  };

  return <div onClick={handleSelectAvatar}>Select as avatar</div>;
};

export default SelectAvatar;
