// App.js

import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Loader from "./components/Loader";
import Mainpage from "./pages/Mainpage";
import Feed from "./pages/Feed";
import Chats from "./pages/Chats";
import MoodJournal from "./pages/MoodJournal";
import Profile from "./pages/Profile";
import Notifications from "./pages/Notifications";
import ClosableOverlay from "./components/ClosableOverlay";
import FollowsList from "./components/FollowsList";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import UserSettings from "./pages/UserSettings";
import Challenges from "./pages/Challenges";
import DoodleEditor from "./components/DoodleEditor/DoodleEditor";
import SinglePost from "./pages/SinglePost";
import { useDoodleContext } from "./contexts/DoodleContext";
import { useUserContext } from "./contexts/UserContext";
import SideMenu from "./components/SideMenu";
import LowerNav from "./components/LowerNav";
import SignUpPrompt from "./components/SignUpPrompt";
import ProtectedRoute from "./components/ProtectedRoute";
import { ToastContainer, toast } from "react-toastify";
import MoodPrompt from "./components/MoodPrompt/MoodPrompt";
import "react-toastify/dist/ReactToastify.css";
import { initializeAnalytics } from "./analytics/Analytics";
import TrackPageView from "./analytics/TrackPageView";
import UserProgressScreen from "./components/UserProgressScreen/UserProgressScreen";
import ReferralTracker from "./components/ReferralTracker";
import useUpdatesFetcher from "./hooks/useUpdatesFetcher";
import { useGlobalState } from "./contexts/GlobalStateContext";
import { AxiosContext } from "./contexts/AxiosContext";
import { ModalProvider } from "./contexts/ModalContext";
import ResetPasswordForm from "./components/UserSettings/ResetPasswordForm";
import SetNewPasswordForm from "./components/UserSettings/SetNewPasswordForm";
import EmailChangeConfirm from "./components/UserSettings/EmailChangeConfirm";
import EmailChangeCancel from "./components/UserSettings/EmailChangeCancel";
import ReactionsFeed from "./components/ReactionsFeed";
import { RemoveScroll } from "react-remove-scroll";
import TelegramWebApp from "./pages/TelegramWebApp";
import TelegramLink from "./components/TelegramLink";

function App() {
  const { onDoodleSubmit, isReactionsOpen, closeReactionsModal } =
    useDoodleContext();

  const {
    isAuthenticated,
    userId,
    userName,
    userAvatar,
    userFollows,
    userFollowers,
    userScore,
    userLevel,
    levelCompletionPercent,
    setIsAuthenticated,
    setUserId,
    setUserName,
    setUserAvatar,
    setUserFollows,
    setUserFollowers,
    follows,
    followers,
    isSignUpPromptOpen,
    handleCloseSignUpPrompt,
  } = useUserContext();

  const {
    showMoodPrompt,
    setShowMoodPrompt,
    instantMessage,
    setInstantMessage,
    isSideMenuOpen,
    setIsSideMenuOpen,
    closeSideMenu,
  } = useGlobalState();

  // Start fetching updates
  useUpdatesFetcher(isAuthenticated);

  // Create Axios instance
  const axiosInstance = React.useContext(AxiosContext);

  // Initialize previous scroll position and scrolling direction
  const prevScrollPos = useRef(0);
  const isScrollingUpRef = useRef(true);
  const [isScrollingUp, setIsScrollingUp] = useState(true);

  // Helper states for opening and closing side menu (main states and closeSideMenu are imported from GlobalStateConfig)
  const menuRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  // Set states for Doodle Editor
  const [isDoodleEditorOpen, setIsDoodleEditorOpen] = useState(false);
  const [editorIntention, setEditorIntention] = useState("postOnMyWall");
  const [existingDoodleId, setExistingDoodleId] = useState(null);
  const [editorContext, setEditorContext] = useState(null);
  const [draftId, setDraftId] = useState(null);

  // Set overlay for 'follows'/'followers' interstitial
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedUsersList, setSelectedUsersList] = useState(null); // 'follows' or 'followers'
  const [usersList, setUsersList] = useState([]);

  // State to control overlay visibility
  const [showProgressOverlay, setShowProgressOverlay] = useState(false);

  // State to track if the page is scrolled down
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      // Determine if the page is scrolled down
      setIsScrolledDown(scrollTop > 0);

      const scrollingUp = prevScrollPos.current > scrollTop;

      if (scrollingUp !== isScrollingUpRef.current) {
        isScrollingUpRef.current = scrollingUp;
        setIsScrollingUp(scrollingUp);
      }

      prevScrollPos.current = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    // Initial check in case the page is not at the top on load
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (follows) {
      setUserFollows(follows);
    }
    if (followers) {
      setUserFollowers(followers);
    }
  }, [follows, followers]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    initializeAnalytics();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axiosInstance
        .post("/check-auth/") // Perform an initial authentication check
        .then((response) => {
          setIsAuthenticated(true);
          setUserId(response.data.id); // Store user ID in the state
          setUserName(response.data.username); // Store user name in the state
          setUserAvatar(response.data.avatar); // Store user avatar in the state
          setUserFollows(response.data.follows); // Store follows count
          setUserFollowers(response.data.followers); // Store followers count
        })
        .catch(() => {
          setIsAuthenticated(false); // Set authentication state to false if authentication fails
          setUserId(null); // Clear user ID if authentication fails
        });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // Function to handle user authentication status changes
  const handleAuthentication = (loggedIn, userId, userName) => {
    setIsAuthenticated(loggedIn);
    setUserId(userId);
    setUserName(userName);
  };

  // Function for handling press of the Open editor button
  const handleOpenDoodleEditor = (
    intention,
    context = null,
    existingDoodleId = null,
    draftId = null
  ) => {
    setIsDoodleEditorOpen(true);
    setEditorIntention(intention);
    setEditorContext(context);
    setExistingDoodleId(existingDoodleId);
    setDraftId(draftId);
  };

  // Function for handling press of the Close editor button
  const handleCloseDoodleEditor = () => {
    setIsDoodleEditorOpen(false);
  };

  // Handle events related to side menu
  const toggleSideMenu = (event) => {
    // Stop event propagation to prevent the click event from reaching the document
    event.stopPropagation();
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const handleClickOutsideSideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsSideMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isSideMenuOpen) {
      document.addEventListener("click", handleClickOutsideSideMenu);
    } else {
      document.removeEventListener("click", handleClickOutsideSideMenu);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsideSideMenu);
    };
  }, [isSideMenuOpen]);

  // Manage follows/followers overlay open and close
  const openOverlay = (listType) => {
    setSelectedUsersList(listType);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setSelectedUsersList(null);
  };

  const handleFollowChange = (newIsFollowed, userId = null) => {
    // Update the follows count on the logged-in user
    setUserFollows((prevUserFollows) =>
      newIsFollowed ? prevUserFollows + 1 : prevUserFollows - 1
    );
    if (userId) {
      // Update the state for the specific user in the overlay
      setUsersList((prevUsersList) => {
        return prevUsersList.map((user) =>
          user.id === userId ? { ...user, is_followed: newIsFollowed } : user
        );
      });
    }
  };

  useEffect(() => {
    if (instantMessage) {
      toast.info(instantMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => setInstantMessage(null), // Clear the message after it's closed
      });
    }
  }, [instantMessage]);

  const handleCloseMoodPrompt = () => {
    setShowMoodPrompt(false);
  };

  // Function to handle overlay toggle
  const toggleProgressOverlay = () => {
    setShowProgressOverlay(!showProgressOverlay);
  };

  return (
    <ModalProvider>
      <div className="common-structure">
        <Router>
          <ReferralTracker />
          <TrackPageView />
          <Header
            setIsAuthenticated={setIsAuthenticated}
            isAuthenticated={isAuthenticated}
            handleOpenDoodleEditor={handleOpenDoodleEditor}
            toggleSideMenu={toggleSideMenu}
          />
          {isDoodleEditorOpen && isAuthenticated && (
            <RemoveScroll>
              <DoodleEditor
                onClose={handleCloseDoodleEditor}
                onDoodleSubmit={onDoodleSubmit}
                editorIntention={editorIntention}
                editorContext={editorContext}
                doodleToEditId={existingDoodleId}
                draftId={draftId}
              />
            </RemoveScroll>
          )}
          {isAuthenticated === null ? (
            <Loader /> // Render a loader component while authentication check is in progress
          ) : (
            <Routes>
              {/* Authentication Routes */}
              <Route
                path="/signin"
                element={
                  <SignIn
                    isAuthenticated={isAuthenticated}
                    handleAuthentication={handleAuthentication}
                  />
                }
              />
              <Route
                path="/signup"
                element={
                  <SignUp
                    isAuthenticated={isAuthenticated}
                    handleAuthentication={handleAuthentication}
                  />
                }
              />
              <Route
                path="/telegram/webapp/"
                element={
                  <TelegramWebApp
                    isAuthenticated={isAuthenticated}
                    handleAuthentication={handleAuthentication}
                  />
                }
              />
              <Route
                path="/telegram/link/:token"
                element={
                  <TelegramLink
                    isAuthenticated={isAuthenticated}
                    handleAuthentication={handleAuthentication}
                  />
                }
              />

              {/* Protected Routes */}
              <Route
                path="/"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Feed
                      handleOpenDoodleEditor={handleOpenDoodleEditor}
                      onFollowChange={handleFollowChange}
                      isScrolledDown={isScrolledDown}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chats"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Chats
                      userId={userId}
                      userName={userName}
                      handleOpenDoodleEditor={handleOpenDoodleEditor}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mood-journal"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <MoodJournal />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Profile
                      userId={userId}
                      userName={userName}
                      handleOpenDoodleEditor={handleOpenDoodleEditor}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile/:pathUsername"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Profile
                      userId={userId}
                      userName={userName}
                      handleOpenDoodleEditor={handleOpenDoodleEditor}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Notifications
                      userId={userId}
                      userName={userName}
                      handleOpenDoodleEditor={handleOpenDoodleEditor}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <UserSettings
                      userId={userId}
                      userName={userName}
                      handleOpenDoodleEditor={handleOpenDoodleEditor}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/challenges"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Challenges
                      userId={userId}
                      userName={userName}
                      handleOpenDoodleEditor={handleOpenDoodleEditor}
                    />
                  </ProtectedRoute>
                }
              />

              {/* Public Routes */}
              <Route path="/reset-password" element={<ResetPasswordForm />} />
              <Route
                path="/reset-password-confirm/:uid/:token"
                element={<SetNewPasswordForm />}
              />
              <Route
                path="/email-change-confirm/:token"
                element={<EmailChangeConfirm />}
              />
              <Route
                path="/email-change-cancel/:token"
                element={<EmailChangeCancel />}
              />
              <Route
                path="/:pathUsername/post/:post_id/r"
                element={
                  <SinglePost
                    handleOpenDoodleEditor={handleOpenDoodleEditor}
                    onFollowChange={handleFollowChange}
                  />
                }
              />
              <Route
                path="/:pathUsername"
                element={
                  <Mainpage
                    userId={userId}
                    userName={userName}
                    handleOpenDoodleEditor={handleOpenDoodleEditor}
                    openOverlay={openOverlay}
                    closeSideMenu={closeSideMenu}
                  />
                }
              />

              {/* Catch-All Route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          )}

          {/* Menu screen */}
          <RemoveScroll enabled={isSideMenuOpen && isMobileView}>
            <SideMenu
              isAuthenticated={isAuthenticated}
              isSideMenuOpen={isSideMenuOpen}
              toggleSideMenu={toggleSideMenu}
              closeSideMenu={closeSideMenu}
              menuRef={menuRef}
              userName={userName}
              userAvatar={userAvatar}
              userFollows={userFollows}
              userFollowers={userFollowers}
              openOverlay={openOverlay}
              setIsAuthenticated={setIsAuthenticated}
              userScore={userScore}
              userLevel={userLevel}
              levelCompletionPercent={levelCompletionPercent}
              toggleProgressOverlay={toggleProgressOverlay}
            />
          </RemoveScroll>

          <LowerNav
            isAuthenticated={isAuthenticated}
            isScrollingUp={isScrollingUp}
            isScrolledDown={isScrolledDown}
          />

          {/* Render follows/followers overlay if open */}
          {isOverlayOpen && (
            <RemoveScroll>
              <ClosableOverlay
                onClose={closeOverlay}
                headerContent={selectedUsersList}
              >
                <FollowsList
                  listType={selectedUsersList}
                  username={userName}
                  loggedInUserId={userId}
                  onFollowChange={handleFollowChange}
                  usersList={usersList}
                  setUsersList={setUsersList}
                  onClose={closeOverlay}
                />
              </ClosableOverlay>
            </RemoveScroll>
          )}
          {showMoodPrompt && isAuthenticated && (
            <MoodPrompt
              onClose={handleCloseMoodPrompt}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
            />
          )}
          {/* Render the overlay component conditionally */}
          {showProgressOverlay && isAuthenticated && (
            <RemoveScroll>
              <ClosableOverlay
                onClose={toggleProgressOverlay}
                headerContent={"Score & Rating"}
              >
                <UserProgressScreen
                  userLevel={userLevel}
                  userScore={userScore}
                  levelCompletionPercent={levelCompletionPercent}
                  onClose={toggleProgressOverlay}
                  userName={userName}
                />
              </ClosableOverlay>
            </RemoveScroll>
          )}
          {isSignUpPromptOpen && (
            <RemoveScroll>
              <ClosableOverlay onClose={handleCloseSignUpPrompt}>
                <SignUpPrompt onClose={handleCloseSignUpPrompt} />
              </ClosableOverlay>
            </RemoveScroll>
          )}
          {isReactionsOpen && (
            <RemoveScroll>
              <ClosableOverlay
                onClose={closeReactionsModal}
                headerContent={"Post"}
              >
                <ReactionsFeed
                  userId={userId}
                  userName={userName}
                  handleOpenDoodleEditor={handleOpenDoodleEditor}
                />
              </ClosableOverlay>
            </RemoveScroll>
          )}
        </Router>
        {isAuthenticated && (
          <div
            className={`plus-icon ${
              isScrolledDown && !isScrollingUp ? "semi-transparent" : ""
            }`}
            onClick={() => handleOpenDoodleEditor("postOnMyWall")}
          >
            +
          </div>
        )}
        <ToastContainer />
      </div>
    </ModalProvider>
  );
}

export default App;
