// Header.js

import React from "react";
import { useLocation } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import { useUserContext } from "../contexts/UserContext";

function Header({ isAuthenticated, toggleSideMenu }) {
  const location = useLocation();
  const { userName, userAvatar } = useUserContext();

  // Define paths where the Header should not be displayed
  const hideHeaderPaths = ["/signin", "/signup"];

  // Check if the current path is in the list of paths to hide the header
  if (hideHeaderPaths.includes(location.pathname)) {
    return null; // Return null to hide the header on specific paths
  }

  return (
    <header className="header">
      {/* Upper navigation bar */}
      <div className="upper-nav">
        {isAuthenticated ? (
          <div onClick={toggleSideMenu}>
            <UserAvatar avatarUrl={userAvatar?.thumbnail} username={userName} />
          </div>
        ) : (
          <div className="avatar-placeholder" /> // Empty div to maintain spacing
        )}

        {/* Service name in the center, always shown */}
        <div className="logo logo-gradient">Cavegram</div>
      </div>
    </header>
  );
}

export default Header;
