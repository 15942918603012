import React, { useState } from "react";
import { Link } from "react-router-dom";
import Like from "./Like";
import Repost from "./Repost";
import Delete from "./Delete";
import Follow from "./Follow";
import UserAvatar from "./UserAvatar";
import SelectAvatar from "../components/SelectAvatar";
import DrawReaction from "./DrawReaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../contexts/UserContext";
import CustomContextMenu from "./CustomContextMenu";
import { formatDateTime } from "../utils/dateUtils";
import { useDoodleContext } from "../contexts/DoodleContext";
import { useGlobalState } from "../contexts/GlobalStateContext";
import ImageWithLoader from "../components/ImageWithLoader";

const Doodle = ({
  doodle,
  typeOfDoodle,
  isViewingOwnWall,
  feedType,
  handleOpenDoodleEditor,
  reactionsButtonDisabled,
  onFollowChange,
  isSuitableForAvatar,
}) => {
  const { userId } = useUserContext();
  const { isReactionsOpen, setIsReactionsOpen } = useDoodleContext();
  const { setInstantMessage } = useGlobalState();

  const handleUserLinkClick = () => {
    if (isReactionsOpen) {
      setIsReactionsOpen(false);
    }
  };

  // Handler function to copy post URL to clipboard and notify the user
  const handleCopyPostUrl = () => {
    const postUrl = `https://cavegram.com/${doodle.belongs_to_user.username}/post/${doodle.id}`;
    navigator.clipboard
      .writeText(postUrl)
      .then(() => {
        // Use the global instantMessage to notify the user
        setInstantMessage("Post URL copied to clipboard!");
        // Optionally, clear the message after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setInstantMessage("");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy the URL: ", err);
        // Notify the user of the failure
        setInstantMessage("Failed to copy the post URL.");
        // Optionally, clear the error message after a delay
        setTimeout(() => {
          setInstantMessage("");
        }, 2000);
      });
  };

  return (
    <>
      {doodle.reposter && (
        <div className="repost-info">
          <FontAwesomeIcon icon={faRetweet} className="icon" />
          <Link to={`/${doodle.reposter}`} onClick={handleUserLinkClick}>
            {doodle.reposter}
          </Link>{" "}
          reposted
        </div>
      )}
      {doodle.mood && (
        <div className="mood-info">
          Felt <span className="mood-text">{doodle.mood.toLowerCase()}</span>
        </div>
      )}
      <header className="post-header">
        <div className="user-details">
          <Link
            to={`/${doodle.belongs_to_user.username}`}
            onClick={handleUserLinkClick}
          >
            <UserAvatar
              avatarUrl={doodle.belongs_to_user.avatar}
              username={doodle.belongs_to_user.username}
              userLevel={doodle.belongs_to_user.user_level}
            />
          </Link>
          <div className="user-info">
            <div className="user-name">
              <Link
                to={`/${doodle.belongs_to_user.username}`}
                onClick={handleUserLinkClick}
              >
                {doodle.belongs_to_user.username}
              </Link>
            </div>
            <div className="post-date">
              {formatDateTime(doodle.published || doodle.created)}
            </div>
          </div>
        </div>
        <CustomContextMenu>
          {doodle.is_owner && (
            <Delete doodle={doodle} typeOfDoodle={typeOfDoodle} />
          )}
          {doodle.belongs_to_user.id !== userId ? (
            <Follow
              followed={null}
              userId={doodle.belongs_to_user.id}
              isInList={false}
              onFollowChange={onFollowChange}
              className={"menu-item"}
            />
          ) : null}
          {isSuitableForAvatar && isViewingOwnWall && (
            <>
              <div className="menu-item">
                <SelectAvatar doodle={doodle} />
              </div>
              <div
                className="menu-item"
                onClick={() =>
                  handleOpenDoodleEditor(
                    "addProfileEntry",
                    {
                      id: null,
                      is_avatars: true,
                    },
                    doodle.id
                  )
                }
              >
                Draw over
              </div>
            </>
          )}
          {!doodle.is_draft ? (
            <div className="menu-item" onClick={handleCopyPostUrl}>
              Copy post URL
            </div>
          ) : (
            <div
              className="menu-item"
              onClick={() =>
                handleOpenDoodleEditor(
                  "submitDraft",
                  null,
                  doodle.id,
                  doodle.id
                )
              }
            >
              Edit
            </div>
          )}
        </CustomContextMenu>
      </header>
      <ImageWithLoader
        src={doodle.image}
        alt="Doodle"
        containerClassName="post-image"
        imageClassName="doodle-image"
      />
      {!doodle.is_draft && (
        <footer className="post-footer">
          <Like
            doodleId={doodle.id}
            liked={doodle.liked}
            typeOfDoodle={typeOfDoodle}
            likesCounter={doodle.likes_counter}
          />
          <DrawReaction
            doodle={doodle}
            reactionsButtonDisabled={reactionsButtonDisabled}
            handleOpenDoodleEditor={handleOpenDoodleEditor}
          />
          <Repost
            doodle={doodle}
            reposted={doodle.reposted}
            repostsCounter={doodle.reposts_counter}
            isViewingOwnWall={isViewingOwnWall}
            feedType={feedType}
            typeOfDoodle={typeOfDoodle}
            userOwnsDoodle={doodle.is_owner}
          />
        </footer>
      )}
    </>
  );
};

export default Doodle;
