// src/components/UserSettings.js

import React, { useState, useEffect } from "react";
import createAxiosInstance from "../axiosInstance";
import { useUserContext } from "../contexts/UserContext"; // Import the UserContext hook
import Loader from "../components/Loader"; // Import the Loader component

const axiosInstance = createAxiosInstance();

/**
 * Component for managing user settings.
 *
 * @returns {JSX.Element}
 */
const UserSettings = () => {
  const [username, setUsername] = useState("");
  const [publicProfile, setPublicProfile] = useState(true); // State for public_profile
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true); // State for initial loading
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const [email, setEmail] = useState("");
  const [emailChangeInProgress, setEmailChangeInProgress] = useState(false);
  const [pendingNewEmail, setPendingNewEmail] = useState("");
  const [showEmailChangePrompt, setShowEmailChangePrompt] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [emailChangeLoading, setEmailChangeLoading] = useState(false);
  const [emailChangeMessage, setEmailChangeMessage] = useState("");
  const [emailChangeError, setEmailChangeError] = useState(null);

  const { userName, setUserName } = useUserContext(); // Destructure setUserName from UserContext

  useEffect(() => {
    fetchUserSettings();
  }, []);

  /**
   * Fetches user settings from the backend.
   */
  const fetchUserSettings = async () => {
    setInitialLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get("/user/settings/");
      setUsername(response.data.username);
      setEmail(response.data.email);
      setEmailChangeInProgress(response.data.email_change_in_progress);
      setPublicProfile(response.data.public_profile);
      setPendingNewEmail(response.data.pending_new_email);
      setMessage("");
    } catch (error) {
      handleError("Failed to load user settings, please try again later.");
    } finally {
      setInitialLoading(false);
    }
  };

  /**
   * Handles error messages.
   *
   * @param {string} customMessage - Custom error message.
   */
  const handleError = (customMessage) => {
    setError(customMessage || "Something went wrong, please try again later.");
  };

  /**
   * Handles changes to the username input.
   *
   * @param {Object} e - Event object.
   */
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  /**
   * Handles changes to the public profile checkbox.
   *
   * @param {Object} e - Event object.
   */
  const handlePublicProfileChange = (e) => {
    setPublicProfile(e.target.checked);
  };

  /**
   * Handles form submission to update user settings.
   *
   * @param {Object} e - Event object.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setError(null);

    try {
      const payload = {
        username,
        public_profile: publicProfile,
      };

      const response = await axiosInstance.post("/user/settings/", payload);
      setMessage(response.data.message || "Settings updated successfully!");

      // Update the username in UserContext only if it was changed
      if (username !== userName) {
        setUserName(username);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorData = error.response.data;
        // Check for specific errors in the response data
        if (errorData.username) {
          handleError("This username is already taken.");
        } else if (errorData.non_field_errors) {
          handleError(errorData.non_field_errors[0]); // Display first non-field error
        } else {
          handleError("Invalid input.");
        }
      } else {
        handleError();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChangeSubmit = async (e) => {
    e.preventDefault();
    setEmailChangeLoading(true);
    setEmailChangeMessage("");
    setEmailChangeError(null);

    try {
      const payload = {
        password: currentPassword,
        new_email: newEmail,
      };

      const response = await axiosInstance.post("/user/email-change/", payload);
      setEmailChangeMessage(
        response.data.message || "Email change request initiated."
      );
      setCurrentPassword("");
      setNewEmail("");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorData = error.response.data;
        // Display specific error messages
        if (errorData.password) {
          setEmailChangeError(errorData.password[0]);
        } else if (errorData.new_email) {
          setEmailChangeError(errorData.new_email[0]);
        } else if (errorData.non_field_errors) {
          setEmailChangeError(errorData.non_field_errors[0]);
        } else {
          setEmailChangeError("Invalid input.");
        }
      } else {
        setEmailChangeError("Something went wrong, please try again later.");
      }
    } finally {
      setEmailChangeLoading(false);
    }
  };

  /**
   * Renders the main content of the component.
   */
  const renderContent = () => {
    return (
      <div className="user-settings">
        <h2 className="user-settings__title">User Settings</h2>
        {error && (
          <p className="user-settings__error" role="alert">
            {error}
          </p>
        )}
        {message && (
          <p className="user-settings__message" role="status">
            {message}
          </p>
        )}
        <form onSubmit={handleSubmit} className="user-settings__form">
          <div className="user-settings__input-group">
            <label htmlFor="username" className="user-settings__label">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className="user-settings__input"
              placeholder="Enter new username"
              required
              disabled={loading}
            />
          </div>
          <div className="user-settings__input-group email-group">
            <label htmlFor="email" className="user-settings__label">
              Email
            </label>
            <div className="email-field">
              <input
                type="email"
                id="email"
                value={email}
                readOnly
                disabled
                className="user-settings__input"
              />
              {!emailChangeInProgress && (
                <button
                  type="button"
                  className="change-email-button"
                  onClick={() => setShowEmailChangePrompt(true)}
                >
                  Change
                </button>
              )}
            </div>
            {emailChangeInProgress && (
              <p className="email-change-status">
                An email change is in progress. Pending new email:{" "}
                {pendingNewEmail}
              </p>
            )}
          </div>
          <div className="user-settings__input-group checkbox-group">
            <input
              type="checkbox"
              id="public_profile"
              checked={publicProfile}
              onChange={handlePublicProfileChange}
              className="user-settings__checkbox"
              disabled={loading}
            />
            <label
              htmlFor="public_profile"
              className="user-settings__checkbox-label"
            >
              Allow unauthenticated users to view your wall and posts
            </label>
          </div>
          <button
            type="submit"
            className="user-settings__button"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </form>
        {showEmailChangePrompt && (
          <div className="email-change-prompt">
            <div className="prompt-header">
              <h2>Change Email Address</h2>
              <button
                className="close-button"
                onClick={() => setShowEmailChangePrompt(false)}
              >
                &times;
              </button>
            </div>
            <div className="prompt-body">
              <p>
                To change your email address, please enter your current password
                and the new email address. A confirmation link will be sent to
                your new email, and a cancellation link will be sent to your old
                email. The change will be effective after 48 hours once you
                confirm your new email, unless you cancel the request within
                this period.
              </p>
              {emailChangeError && (
                <p className="error-message" role="alert">
                  {emailChangeError}
                </p>
              )}
              {emailChangeMessage && (
                <p className="success-message" role="status">
                  {emailChangeMessage}
                </p>
              )}
              <form onSubmit={handleEmailChangeSubmit}>
                <div className="user-settings__input-group">
                  <label
                    htmlFor="current_password"
                    className="user-settings__label"
                  >
                    Current Password
                  </label>
                  <input
                    type="password"
                    id="current_password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="user-settings__input"
                    required
                    disabled={emailChangeLoading}
                  />
                </div>
                <div className="user-settings__input-group">
                  <label htmlFor="new_email" className="user-settings__label">
                    New Email Address
                  </label>
                  <input
                    type="email"
                    id="new_email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    className="user-settings__input"
                    required
                    disabled={emailChangeLoading}
                  />
                </div>
                <button
                  type="submit"
                  className="user-settings__button"
                  disabled={emailChangeLoading}
                >
                  {emailChangeLoading ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="main-content">
      {initialLoading ? <Loader size="large" /> : renderContent()}
    </div>
  );
};

export default UserSettings;
