// authUtils.js
import axios from "axios";

export const finalizeLogin = (
  data,
  handleAuthentication,
  setUserId,
  setUserName,
  setUserAvatar,
  setUserFollows,
  setUserFollowers
) => {
  const { token, id, username, avatar, follows, followers } = data;

  // save token to local storage
  localStorage.setItem("token", token);
  // update authentication state to true
  handleAuthentication(true);
  // set user details
  setUserId(id);
  setUserName(username);
  setUserAvatar(avatar);
  setUserFollows(follows);
  setUserFollowers(followers);
};

const handleSignIn = async (
  username,
  password,
  handleAuthentication,
  setUserId,
  setUserName,
  setUserAvatar,
  setUserFollows,
  setUserFollowers
) => {
  // Function to get the CSRF token from the cookie
  function getCSRFToken() {
    const name = "csrftoken";
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name + "="))
      ?.split("=")[1];
    return cookieValue;
  }

  try {
    const response = await axios.post(
      `/api/login/`,
      { username, password },
      {
        headers: {
          "X-CSRFToken": getCSRFToken(),
          "Content-Type": "application/json",
        },
      }
    );

    finalizeLogin(
      response.data,
      handleAuthentication,
      setUserId,
      setUserName,
      setUserAvatar,
      setUserFollows,
      setUserFollowers
    );
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        throw new Error("Both user name and password are required");
      } else if (error.response.status === 401) {
        throw new Error("Invalid credentials");
      } else if (error.response.status === 500) {
        throw new Error("Something went wrong, please try again later");
      }
    } else {
      throw new Error("Network error, please try again later");
    }
  }
};

export default handleSignIn;
