// Like.js

import React, { useContext } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { useDoodleContext } from "../contexts/DoodleContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../contexts/UserContext";

const Like = ({ doodleId, liked, typeOfDoodle, likesCounter }) => {
  const { onUpdateLikes } = useDoodleContext();
  const { ShouldShowSignUpPrompt } = useUserContext();
  const axiosInstance = useContext(AxiosContext);

  const handleLike = async () => {
    if (ShouldShowSignUpPrompt()) {
      return; // Block action if sign-up prompt should be shown
    }

    try {
      const action = liked ? "unlike" : "like";
      await axiosInstance.post(`/doodles/${doodleId}/like/`, { action });
      onUpdateLikes(doodleId, !liked, typeOfDoodle);
    } catch (error) {
      console.error("Error liking doodle:", error);
    }
  };

  return (
    <button
      onClick={handleLike}
      className={`button ${liked ? "pressed" : "active"}`}
    >
      {" "}
      <FontAwesomeIcon icon={faHeart} className="icon" />
      <span className="counter">{likesCounter}</span>
    </button>
  );
};

export default Like;
