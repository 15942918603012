import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ChatOverlay from "../components/ChatOverlay";
import { useDoodleContext } from "../contexts/DoodleContext";
import UserAvatar from "../components/UserAvatar";
import { useUserContext } from "../contexts/UserContext";
import Loader from "../components/Loader";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";
import { formatDateTime } from "../utils/dateUtils";
import ImageWithLoader from "../components/ImageWithLoader";
import { RemoveScroll } from "react-remove-scroll";
import ReactDOM from "react-dom";

const Chats = ({ handleOpenDoodleEditor }) => {
  const [chatId, setChatId] = useState(null);
  const [companionUsername, setCompanionUsername] = useState(null);
  const [companionAvatar, setCompanionAvatar] = useState(false);

  const { userId, userName } = useUserContext();
  const { chats, isChatOverlayOpen, setChats, setIsChatOverlayOpen } =
    useDoodleContext();

  const initialUrl = "/chats/";
  const { items, setItems, total, loading, error, loadMore, hasMore, setUrl } =
    usePagination(initialUrl, 10);

  const [isDebouncing, setIsDebouncing] = useState(false);

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing && hasMore && !loading) {
      loadMore();
      setIsDebouncing(true);
      setTimeout(() => {
        setIsDebouncing(false);
      }, 100);
    }
  }, [isDebouncing, hasMore, loading, loadMore]);

  useEffect(() => {
    setChats(items);
  }, [items, setChats]);

  useEffect(() => {
    // Ensure initial chat load
    setUrl(initialUrl);
    setItems([]);
  }, [initialUrl, setUrl, setItems]);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  useEffect(() => {
    if (inView && hasMore && !loading) {
      debouncedLoadMore();
    }
  }, [inView, hasMore, loading, debouncedLoadMore]);

  const onOpenChatOverlay = () => {
    setIsChatOverlayOpen(true);
  };

  const onCloseChatOverlay = () => {
    setIsChatOverlayOpen(false);
    setCompanionUsername(null);
    setCompanionAvatar(null);
  };

  useEffect(() => {
    if (chatId && companionUsername && companionAvatar !== false) {
      onOpenChatOverlay();
    }
  }, [chatId, companionUsername, companionAvatar]);

  const viewChat = (chat_id, companionUsername, companionAvatar) => {
    setChatId(chat_id);
    setCompanionUsername(companionUsername);
    setCompanionAvatar(companionAvatar);
  };

  return (
    <div className="main-content">
      {isChatOverlayOpen &&
        ReactDOM.createPortal(
          <RemoveScroll>
            <ChatOverlay
              chatId={chatId}
              userName={userName}
              onClose={onCloseChatOverlay}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
              companionUsername={companionUsername}
              companionAvatar={companionAvatar}
            />
          </RemoveScroll>,
          document.getElementById("modal-root")
        )}
      <h1>Chats</h1>
      <div className="feed">
        <div className="chat-list">
          {chats.map((chat) => (
            <div
              className="chat-item"
              key={chat.id}
              onClick={() =>
                viewChat(
                  chat.id,
                  chat.participants[0].username,
                  chat.participants[0].avatar
                )
              }
            >
              <Link to={`/${chat.participants[0].username}`}>
                <UserAvatar
                  avatarUrl={chat.participants[0].avatar}
                  username={chat.participants[0].username}
                  userLevel={chat.participants[0].user_level}
                />
              </Link>
              <div className="chat-info">
                <span>
                  <Link
                    to={`/${chat.participants[0].username}`}
                    className="user-name"
                  >
                    {chat.participants[0].username}
                  </Link>
                </span>
                {chat.last_message ? (
                  <span className="last-message-time">
                    {formatDateTime(chat.last_message.created)}
                  </span>
                ) : (
                  <span className="last-message-time">empty chat</span>
                )}
              </div>
              {chat.last_message ? (
                <div className="image-wrapper">
                  <ImageWithLoader
                    src={chat.last_message.thumbnail}
                    alt="Message thumbnail"
                    containerClassName="image-loader-container"
                    imageClassName="message-thumbnail"
                  />
                </div>
              ) : (
                <div className="dummy-thumbnail"></div>
              )}
              {chat.unread_messages_counter > 0 && (
                <div className="unread-counter">
                  {chat.unread_messages_counter}
                </div>
              )}
              {chat.last_message &&
                chat.last_message.belongs_to_user.id === userId && (
                  <span
                    className={`read-indicator ${
                      chat.is_last_message_read ? "read" : "unread"
                    }`}
                  ></span>
                )}
            </div>
          ))}
          {loading && <Loader />}
          {!loading && !hasMore && chats.length === 0 && (
            <p>No chats available.</p>
          )}
          {error && <p>{error}</p>}
          <div ref={ref}></div> {/* Intersection observer target */}
        </div>
      </div>
    </div>
  );
};

export default Chats;
