// Mainpage.js

import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Wall from "../components/Wall";
import Loader from "../components/Loader";
import { useDoodleContext } from "../contexts/DoodleContext";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";

const Mainpage = ({
  userId,
  userName,
  handleOpenDoodleEditor,
  openOverlay,
}) => {
  // Get username from route
  const { pathUsername } = useParams();

  // State for feed type toggle (posts or reactions)
  const [feedType, setFeedType] = useState("posts"); // Default to "posts"

  const currentApiUrl = pathUsername
    ? `/doodles/${pathUsername}?type=${feedType}`
    : `/doodles/?type=${feedType}`;

  const { doodles, setDoodles, onUpdateLikes } = useDoodleContext();

  // Use pagination hook
  const {
    items: paginatedDoodles,
    setItems,
    total,
    loading,
    error,
    loadMore,
    hasMore,
    url,
    setUrl,
    page,
    setPage,
  } = usePagination(currentApiUrl, 10);

  // Update the shared doodles state whenever paginatedDoodles changes
  useEffect(() => {
    setDoodles(paginatedDoodles);
  }, [paginatedDoodles, setDoodles]);

  // Setup intersection observer only after user interaction
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setInitialized(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Fetch doodles for rendering on the Wall
  const fetchDoodles = useCallback(() => {
    loadMore()
      .then(() => {
        setInitialized(false); // Reset initialized state after successful page load
        setPaginationResetNeeded(false);
      })
      .catch(() => {
        setPaginationResetNeeded(false); // Ensure it's reset even if an error occurs
      });
  }, [loadMore]);

  // State for managing pagination status reset
  const [paginationResetNeeded, setPaginationResetNeeded] = useState(false);

  useEffect(() => {
    // If url is current, page is 1, and paginatedDoodles is empty, then it is the default state - fetch doodles
    if (url === currentApiUrl && page === 1 && paginatedDoodles.length === 0) {
      fetchDoodles();
    } else {
      // If the above condition is not met, then the page was switched or feedType changed
      setPaginationResetNeeded(true);
    }
  }, [pathUsername, feedType]);

  useEffect(() => {
    // Reset states in the usePagination hook if paginationResetNeeded=true
    if (paginationResetNeeded) {
      setItems([]);
      setUrl(currentApiUrl);
      setPage(1);
    }
  }, [paginationResetNeeded, currentApiUrl, setItems, setUrl, setPage]);

  useEffect(() => {
    // Check if pagination states have finished resetting
    if (url === currentApiUrl && page === 1 && paginationResetNeeded) {
      // If all pagination states have finished resetting, then fetch doodles
      fetchDoodles();
    }
  }, [url, page, paginationResetNeeded, currentApiUrl, fetchDoodles]);

  // Setup intersection observer
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "100px",
  });

  // Load more doodles when inView becomes true
  useEffect(() => {
    if (inView && hasMore && !loading && initialized) {
      fetchDoodles(); // Call fetchDoodles instead of loadMore directly
    }
  }, [inView, hasMore, loading, initialized, fetchDoodles]);

  // Toggle feed type handler
  const handleToggleFeedType = useCallback(
    (type) => {
      if (type !== feedType) {
        setFeedType(type);
        setPaginationResetNeeded(true); // Trigger pagination reset
      }
    },
    [feedType]
  );

  return (
    <main className="main-content">
      {/* Removed the existing feed-toggle div with buttons */}
      <Wall
        doodles={doodles}
        onUpdateLikes={onUpdateLikes}
        loggedInUserId={userId}
        loggedInUserName={userName}
        pathUsername={pathUsername}
        handleOpenDoodleEditor={handleOpenDoodleEditor}
        feedType={feedType}
        handleToggleFeedType={handleToggleFeedType}
        openOverlay={openOverlay}
      />
      {loading && <Loader />}
      {error && <p>{error}</p>}
      {hasMore && <div ref={ref} />}{" "}
      {/* Intersection observer target element */}
    </main>
  );
};

export default Mainpage;
