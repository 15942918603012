// src/components/TelegramLink.js

import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AxiosContext } from "../contexts/AxiosContext";

const TelegramLink = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { token } = useParams();
  const [success, setSuccess] = useState(false);
  const axiosInstance = useContext(AxiosContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axiosInstance.post(
        "/telegram/link/",
        {
          token,
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        // Optionally, navigate to another page after success
        // navigate("/desired-route");
      }
    } catch (err) {
      setError(err.response?.data?.error || "An error occurred.");
    }
  };

  return (
    <div className="main-content">
      <div className="set-new-password-container">
        {success ? (
          <div className="success-message">Account linked successfully!</div>
        ) : (
          <>
            <h2>Link Account to Telegram</h2>
            <form onSubmit={handleSubmit} className="set-new-password-form">
              {error && <div className="error-message">{error}</div>}
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit" className="set-new-password-button">
                Link Account
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default TelegramLink;
