// Repost.js

import React, { useContext } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { useDoodleContext } from "../contexts/DoodleContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../contexts/UserContext";

const Repost = ({
  doodle,
  reposted,
  isViewingOwnWall,
  feedType,
  typeOfDoodle,
  repostsCounter,
  userOwnsDoodle,
}) => {
  const axiosInstance = useContext(AxiosContext);
  const { onUpdateReposts, onDoodleSubmit } = useDoodleContext();
  const { ShouldShowSignUpPrompt } = useUserContext();

  // Handler for reposting the doodle
  const handleRepost = async () => {
    if (ShouldShowSignUpPrompt()) return; // Show sign-up prompt if unauthenticated

    try {
      const action = reposted ? "unrepost" : "repost";
      await axiosInstance
        .post(`/doodles/${doodle.id}/repost/`, { action })
        .then((response) => {
          // If a repost has happened
          if (action === "repost" && isViewingOwnWall && feedType === "posts") {
            // Add the new repost to the background page (Wall or Feed)
            onDoodleSubmit(response.data, "postOnMyWall");
          }

          // Update button and repost counter on the Reaction in the ReactionsFeed screen
          onUpdateReposts(
            doodle,
            !reposted,
            isViewingOwnWall,
            feedType,
            typeOfDoodle
          );
        });
    } catch (error) {
      console.error("Error reposting doodle:", error);
    }
  };

  // Determine if the button should be inactive - we will allow reposts of user's own reactions so that they can be seen on user's wall
  const isInactive = userOwnsDoodle && doodle.is_reaction_to === null;

  return (
    <button
      onClick={!isInactive ? handleRepost : null}
      className={`button ${
        isInactive ? "inactive" : reposted ? "pressed" : "active"
      }`}
    >
      <FontAwesomeIcon icon={faRetweet} className="icon" />
      <span className="counter">{repostsCounter}</span>
    </button>
  );
};

export default Repost;
