import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Post from "../components/Post";
import Loader from "../components/Loader";
import { AxiosContext } from "../contexts/AxiosContext";
import { useDoodleContext } from "../contexts/DoodleContext";

const SinglePost = ({
  handleOpenDoodleEditor,
  onFollowChange,
  isViewingOwnWall,
}) => {
  const { pathUsername, post_id } = useParams();

  const { doodles, setDoodles } = useDoodleContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const axiosInstance = useContext(AxiosContext);

  useEffect(() => {
    const fetchDoodle = async () => {
      try {
        const response = await axiosInstance.get(
          `/single-post/${pathUsername}/${post_id}/`
        );
        // Store the single doodle in an array
        setDoodles([response.data]);
        setLoading(false);
      } catch (err) {
        const errorMessage =
          err.response?.data?.detail ||
          (err.response?.status === 404
            ? "There is no such post. It may have been deleted or does not exist."
            : err.response?.data?.message) ||
          "Error loading the post. Please try again later.";

        setError(errorMessage);
        setLoading(false);
      }
    };

    fetchDoodle();
  }, [post_id, pathUsername, axiosInstance]);

  if (loading) {
    return (
      <main className="main-content">
        <Loader />
      </main>
    );
  }

  if (error) {
    return (
      <main className="main-content">
        <p>{error}</p>
      </main>
    );
  }

  // Since we know doodles is an array with one doodle, we use doodles[0].
  const doodle = doodles[0];

  return (
    <main className="main-content">
      <div className="feed">
        <div className="single-post">
          <Post
            key={doodle.id}
            doodle={doodle}
            typeOfDoodle={"doodles"}
            pathUsername={pathUsername}
            handleOpenDoodleEditor={handleOpenDoodleEditor}
            onFollowChange={onFollowChange}
            isViewingOwnWall={isViewingOwnWall}
          />
        </div>
      </div>
    </main>
  );
};

export default SinglePost;
