// DoodleEditor.js

import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { AxiosContext } from "../../contexts/AxiosContext";
import { useDoodleContext } from "../../contexts/DoodleContext";
import { hashImage, generateMetaHash } from "./hashUtils";
import ToolSelector from "./ToolSelector";
import ColorPicker from "./ColorPicker";
import Loader from "../Loader";
import DrawingArea from "./DrawingArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationPrompt from "../ConfirmationPrompt";
import {
  faTimes,
  faTrash,
  faRotateLeft,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";

const DoodleEditor = ({
  onClose,
  editorIntention,
  editorContext,
  doodleToEditId = null,
  draftId = null,
}) => {
  const axiosInstance = useContext(AxiosContext);
  const { onDoodleSubmit } = useDoodleContext();

  // State Management
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedTool, setSelectedTool] = useState("charcoal");
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedColorLabel, setSelectedColorLabel] = useState("Black");
  const [colorQuantities, setColorQuantities] = useState({});
  const [toolDurabilities, setToolDurabilities] = useState({});
  const [lastUsedColor, setLastUsedColor] = useState({
    color: "#000000",
    label: "Black",
  });

  const [colorHistory, setColorHistory] = useState({});
  const [toolHistory, setToolHistory] = useState({});
  const [redoLines, setRedoLines] = useState([]);
  const [redoColorHistory, setRedoColorHistory] = useState({});
  const [redoToolHistory, setRedoToolHistory] = useState({});

  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [brushTextureSrc, setBrushTextureSrc] = useState(null);
  const [colorSpendFactor, setColorSpendFactor] = useState(1);
  const [durabilitySpendFactor, setDurabilitySpendFactor] = useState(1);
  const [isPosting, setIsPosting] = useState(false);
  const [sessionKey, setSessionKey] = useState(null);
  const [shareOnWall, setShareOnWall] = useState(false);
  const [existingDoodleImage, setExistingDoodleImage] = useState(null);
  const [compressionLevel, setCompressionLevel] = useState(1);
  const [isConfirmingClear, setIsConfirmingClear] = useState(false); // State for clear canvas confirmation prompt
  const [isConfirmingClose, setIsConfirmingClose] = useState(false); // State for close editor confirmation prompt

  // Refs to hold mutable values
  const changedPixels = useRef({});
  const allChangedPixels = useRef({});
  const exactQuantities = useRef({});
  const initialQuantities = useRef({});
  const exactDurabilities = useRef({});
  const initialDurabilities = useRef({});

  // Ref to track if the user is interacting (drawing)
  const isInteractingRef = useRef(false);

  // Track global mouse and touch interactions to manage isInteractingRef
  useEffect(() => {
    const handleMouseDown = () => {
      isInteractingRef.current = true;
    };

    const handleMouseUp = () => {
      isInteractingRef.current = false;
    };

    const handleTouchStart = () => {
      isInteractingRef.current = true;
    };

    const handleTouchEnd = () => {
      isInteractingRef.current = false;
    };

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  // Initialize Editor Configuration
  useEffect(() => {
    const payload = doodleToEditId ? { get_doodle_id: doodleToEditId } : {};

    axiosInstance
      .post("/editor/", payload)
      .then((response) => {
        setConfig(response.data);
        setSessionKey(response.data.session_key);
        setCompressionLevel(response.data.compression_level || 1);

        const initialQuantitiesData = {};
        const initialDurabilitiesData = {};
        const initialColorHistory = {};
        const initialToolHistory = {};
        const initialRedoColorHistory = {};
        const initialRedoToolHistory = {};

        // Initialize color quantities and changed pixels
        Object.keys(response.data.colors).forEach((key) => {
          const quantity = response.data.colors[key].quantity || Infinity;
          initialQuantitiesData[key] = quantity;
          exactQuantities.current[key] = quantity;
          changedPixels.current[key] = new Set();
          allChangedPixels.current[key] = new Set();

          // Initialize history stacks only for colors with quantities
          if (quantity !== Infinity) {
            initialColorHistory[key] = [];
            initialRedoColorHistory[key] = [];
          }
        });

        // Initialize tool durabilities
        Object.keys(response.data.tools).forEach((key) => {
          const durability = response.data.tools[key].durability || Infinity;
          initialDurabilitiesData[key] = durability;
          exactDurabilities.current[key] = durability;

          // Initialize history stacks only for tools with durabilities
          if (durability !== Infinity) {
            initialToolHistory[key] = [];
            initialRedoToolHistory[key] = [];
          }
        });

        // Initialize colors for tools without palettes (default tools/colors)
        Object.values(response.data.tools).forEach((tool) => {
          if (!tool.has_palette) {
            const colorCode = tool.default_color.color;
            if (!initialQuantitiesData[colorCode]) {
              initialQuantitiesData[colorCode] = Infinity;
              exactQuantities.current[colorCode] = Infinity;
              changedPixels.current[colorCode] = new Set();
              allChangedPixels.current[colorCode] = new Set();
              // No history initialization for default colors
            }
          }
        });

        setColorQuantities(initialQuantitiesData);
        setToolDurabilities(initialDurabilitiesData);
        initialQuantities.current = { ...initialQuantitiesData };
        initialDurabilities.current = { ...initialDurabilitiesData };
        setColorHistory(initialColorHistory);
        setToolHistory(initialToolHistory);
        setRedoColorHistory(initialRedoColorHistory);
        setRedoToolHistory(initialRedoToolHistory);
        setColorSpendFactor(response.data.color_spend_factor || 1);
        setDurabilitySpendFactor(response.data.durability_spend_factor || 1);

        if (
          response.data.existing_doodle &&
          response.data.existing_doodle.doodle_image
        ) {
          setExistingDoodleImage(response.data.existing_doodle.doodle_image);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching editor config:", error);
        setLoading(false);
      });
  }, [axiosInstance, doodleToEditId]);

  // Update selected tool and color based on config
  useEffect(() => {
    if (config) {
      const tool = config.tools[selectedTool];
      setBrushTextureSrc(tool.texture);
      if (!tool.has_palette) {
        setSelectedColor(tool.default_color.color);
        setSelectedColorLabel(tool.default_color.name);
      } else {
        const defaultColorKey = Object.keys(config.colors)[0];
        setSelectedColor(config.colors[defaultColorKey].color);
        setSelectedColorLabel(config.colors[defaultColorKey].label);
      }
    }
  }, [config, selectedTool]);

  // Function to handle color selection
  const handleColorSelect = (color, label) => {
    setSelectedColor(color);
    setSelectedColorLabel(label);

    // Store color if the current tool supports a palette
    if (config?.tools[selectedTool]?.has_palette) {
      setLastUsedColor({ color, label });
    }
  };

  // Update selected tool and color based on config
  useEffect(() => {
    if (config) {
      const tool = config.tools[selectedTool];
      setBrushTextureSrc(tool.texture);
      if (!tool.has_palette) {
        // Default tool color
        setSelectedColor(tool.default_color.color);
        setSelectedColorLabel(tool.default_color.name);
      } else {
        // Apply last used color for palette tools
        setSelectedColor(lastUsedColor.color);
        setSelectedColorLabel(lastUsedColor.label);
      }
    }
  }, [config, selectedTool, lastUsedColor]);

  // Function to clear redo stacks when a new action is performed
  const clearRedoStacks = useCallback(() => {
    // Clear redoLines
    setRedoLines([]);

    // Clear redoColorHistory for all colors
    const newRedoColorHistory = {};
    Object.keys(redoColorHistory).forEach((key) => {
      newRedoColorHistory[key] = [];
    });
    setRedoColorHistory(newRedoColorHistory);

    // Clear redoToolHistory for all tools
    const newRedoToolHistory = {};
    Object.keys(redoToolHistory).forEach((key) => {
      newRedoToolHistory[key] = [];
    });
    setRedoToolHistory(newRedoToolHistory);
  }, [redoColorHistory, redoToolHistory]);

  // Handle Undo functionality
  const handleUndo = useCallback(() => {
    if (lines.length === 0) return;

    const lastLine = lines[lines.length - 1];
    const colorKey = lastLine.colorKey;
    const toolKey = lastLine.tool;

    // Only process colors with quantities
    if (
      exactQuantities.current[colorKey] !== Infinity &&
      colorHistory[colorKey] &&
      colorHistory[colorKey].length > 0
    ) {
      const lastHistory =
        colorHistory[colorKey][colorHistory[colorKey].length - 1];

      // Save current changedPixels and allChangedPixels for redo
      const currentChangedPixels = new Set(changedPixels.current[colorKey]);
      const currentAllChangedPixels = new Set(
        allChangedPixels.current[colorKey]
      );

      // Restore previous changedPixels and allChangedPixels
      if (lastHistory.previousChangedPixels) {
        changedPixels.current[colorKey] = new Set(
          lastHistory.previousChangedPixels
        );
      }
      if (lastHistory.previousAllChangedPixels) {
        allChangedPixels.current[colorKey] = new Set(
          lastHistory.previousAllChangedPixels
        );
      }

      // Restore the initial quantity
      exactQuantities.current[colorKey] = lastHistory.initialQuantity;
      setColorQuantities((prevQuantities) => ({
        ...prevQuantities,
        [colorKey]: Math.round(lastHistory.initialQuantity),
      }));

      // Remove the last history entry
      setColorHistory((prevHistory) => ({
        ...prevHistory,
        [colorKey]: prevHistory[colorKey].slice(0, -1),
      }));

      // Add this history entry to redoColorHistory, including current changed pixels
      setRedoColorHistory((prevRedo) => ({
        ...prevRedo,
        [colorKey]: [
          ...(prevRedo[colorKey] || []),
          {
            ...lastHistory,
            changedPixels: currentChangedPixels,
            allChangedPixels: currentAllChangedPixels,
          },
        ],
      }));
    }

    // Only process tools with durabilities
    if (
      exactDurabilities.current[toolKey] !== Infinity &&
      toolHistory[toolKey] &&
      toolHistory[toolKey].length > 0
    ) {
      const lastToolHistory =
        toolHistory[toolKey][toolHistory[toolKey].length - 1];

      // Restore the initial durability
      exactDurabilities.current[toolKey] = lastToolHistory.initialDurability;
      setToolDurabilities((prevDurabilities) => ({
        ...prevDurabilities,
        [toolKey]: Math.round(lastToolHistory.initialDurability),
      }));

      // Remove the last history entry
      setToolHistory((prevHistory) => ({
        ...prevHistory,
        [toolKey]: prevHistory[toolKey].slice(0, -1),
      }));

      // Add this history entry to redoToolHistory
      setRedoToolHistory((prevRedo) => ({
        ...prevRedo,
        [toolKey]: [...(prevRedo[toolKey] || []), lastToolHistory],
      }));
    }

    // Remove the last line from lines
    setLines((prevLines) => prevLines.slice(0, -1));

    // Add the last line to redoLines
    setRedoLines((prevRedoLines) => [...prevRedoLines, lastLine]);
  }, [lines, colorHistory, toolHistory]);

  // Handle Redo functionality
  const handleRedo = useCallback(() => {
    if (redoLines.length === 0) return;

    const lastRedoLine = redoLines[redoLines.length - 1];
    const colorKey = lastRedoLine.colorKey;
    const toolKey = lastRedoLine.tool;

    // Only process colors with quantities
    if (
      exactQuantities.current[colorKey] !== Infinity &&
      redoColorHistory[colorKey] &&
      redoColorHistory[colorKey].length > 0
    ) {
      const lastRedoHistory =
        redoColorHistory[colorKey][redoColorHistory[colorKey].length - 1];

      // Save current changedPixels and allChangedPixels for undo
      const currentChangedPixels = new Set(changedPixels.current[colorKey]);
      const currentAllChangedPixels = new Set(
        allChangedPixels.current[colorKey]
      );

      // Restore changedPixels and allChangedPixels
      if (lastRedoHistory.newChangedPixels) {
        changedPixels.current[colorKey] = new Set(
          lastRedoHistory.newChangedPixels
        );
      }
      if (lastRedoHistory.newAllChangedPixels) {
        allChangedPixels.current[colorKey] = new Set(
          lastRedoHistory.newAllChangedPixels
        );
      }

      // Apply the new quantity
      exactQuantities.current[colorKey] = lastRedoHistory.newExactQuantity;
      setColorQuantities((prevQuantities) => ({
        ...prevQuantities,
        [colorKey]: Math.round(lastRedoHistory.newExactQuantity),
      }));

      // Remove the last redo history entry
      setRedoColorHistory((prevRedo) => ({
        ...prevRedo,
        [colorKey]: prevRedo[colorKey].slice(0, -1),
      }));

      // Add this history entry back to colorHistory, including previous changed pixels
      setColorHistory((prevHistory) => ({
        ...prevHistory,
        [colorKey]: [
          ...(prevHistory[colorKey] || []),
          {
            ...lastRedoHistory,
            previousChangedPixels: currentChangedPixels,
            previousAllChangedPixels: currentAllChangedPixels,
          },
        ],
      }));
    }

    // Only process tools with durabilities
    if (
      exactDurabilities.current[toolKey] !== Infinity &&
      redoToolHistory[toolKey] &&
      redoToolHistory[toolKey].length > 0
    ) {
      const lastRedoToolHistory =
        redoToolHistory[toolKey][redoToolHistory[toolKey].length - 1];

      // Apply the new durability
      exactDurabilities.current[toolKey] =
        lastRedoToolHistory.newExactDurability;
      setToolDurabilities((prevDurabilities) => ({
        ...prevDurabilities,
        [toolKey]: Math.round(lastRedoToolHistory.newExactDurability),
      }));

      // Remove the last redo tool history entry
      setRedoToolHistory((prevRedo) => ({
        ...prevRedo,
        [toolKey]: prevRedo[toolKey].slice(0, -1),
      }));

      // Add this history entry back to toolHistory
      setToolHistory((prevHistory) => ({
        ...prevHistory,
        [toolKey]: [...(prevHistory[toolKey] || []), lastRedoToolHistory],
      }));
    }

    // Add the redo line back to lines
    setLines((prevLines) => [...prevLines, lastRedoLine]);

    // Remove the line from redoLines
    setRedoLines((prevRedoLines) => prevRedoLines.slice(0, -1));
  }, [redoLines, redoColorHistory, toolHistory]);

  // Handle Clear functionality with confirmation
  const handleClear = useCallback(() => {
    // Show the confirmation prompt
    setIsConfirmingClear(true);
  }, []);

  // Function to cancel the clear action
  const cancelClear = useCallback(() => {
    setIsConfirmingClear(false);
  }, []);

  // Clear the canvas and reset states
  const confirmClear = () => {
    setLines([]);
    setRedoLines([]);
    setRedoColorHistory({});
    setRedoToolHistory({});

    // Reset color quantities
    const resetQuantities = {};
    Object.keys(colorQuantities).forEach((key) => {
      if (config.colors[key]) {
        resetQuantities[key] = config.colors[key].quantity || Infinity;
        exactQuantities.current[key] = config.colors[key].quantity || Infinity;
      }
    });
    setColorQuantities(resetQuantities);
    initialQuantities.current = { ...resetQuantities };

    // Reset tool durabilities
    const resetDurabilities = {};
    Object.keys(toolDurabilities).forEach((key) => {
      if (config.tools[key]) {
        resetDurabilities[key] = config.tools[key].durability || Infinity;
        exactDurabilities.current[key] =
          config.tools[key].durability || Infinity;
      }
    });
    setToolDurabilities(resetDurabilities);
    initialDurabilities.current = { ...resetDurabilities };

    // Reset color and tool histories
    const resetColorHistory = {};
    Object.keys(colorHistory).forEach((key) => {
      resetColorHistory[key] = [];
    });
    setColorHistory(resetColorHistory);

    const resetToolHistory = {};
    Object.keys(toolHistory).forEach((key) => {
      resetToolHistory[key] = [];
    });
    setToolHistory(resetToolHistory);

    // Reset redo histories
    const resetRedoColorHistory = {};
    Object.keys(redoColorHistory).forEach((key) => {
      resetRedoColorHistory[key] = [];
    });
    setRedoColorHistory(resetRedoColorHistory);

    const resetRedoToolHistory = {};
    Object.keys(redoToolHistory).forEach((key) => {
      resetRedoToolHistory[key] = [];
    });
    setRedoToolHistory(resetRedoToolHistory);

    // Clear changed pixels
    Object.keys(allChangedPixels.current).forEach((key) => {
      if (changedPixels.current[key]) {
        allChangedPixels.current[key].clear();
        changedPixels.current[key].clear();
      }
    });

    // Hide the confirmation prompt
    setIsConfirmingClear(false);
  };

  // Handle Clear functionality with confirmation
  const handleClose = useCallback(() => {
    // Show the confirmation prompt
    setIsConfirmingClose(true);
  }, []);

  // Function to cancel the clear action
  const cancelClose = useCallback(() => {
    // Close the "close editor" confirmation prompt
    setIsConfirmingClose(false);
  }, []);

  // Function to cancel the clear action
  const confirmClose = useCallback(() => {
    // Close the "close editor" confirmation prompt
    setIsConfirmingClose(false);
    // Close the editor
    onClose();
  }, []);

  // Handle posting the doodle
  const handlePost = (isDraft = false) => {
    setIsPosting(true); // Lock the button and show the loader
    const stage = document.querySelector("canvas");

    if (!stage) {
      console.error("Canvas element not found");
      setIsPosting(false);
      return;
    }

    stage.toBlob(
      async (blob) => {
        if (!blob) {
          console.error(
            "Failed to create blob from canvas data. Blob is null."
          );
          setIsPosting(false);
          return;
        }

        try {
          // Check if blob is an instance of Blob
          if (!(blob instanceof Blob)) {
            throw new Error("Invalid image blob.");
          }

          const formData = new FormData();
          formData.append("image", blob, "doodle.webp");
          formData.append("intention", editorIntention);

          // Form context based on the intention
          const context =
            editorIntention === "drawReasonForMood"
              ? { ...editorContext, shareOnWall }
              : editorContext;

          formData.append("context", JSON.stringify(context));
          formData.append("is_draft", isDraft);

          // Include draftId if editing an existing draft
          if (draftId) {
            formData.append("draft_id", draftId);
          }

          // Calculate spent quantities
          const spentQuantities = {};
          Object.keys(exactQuantities.current).forEach((key) => {
            const spent =
              initialQuantities.current[key] - exactQuantities.current[key];
            if (spent > 0 && config.colors[key]) {
              spentQuantities[config.colors[key].id] = Math.round(spent);
            }
          });

          formData.append("colors", JSON.stringify(spentQuantities));

          // Calculate spent durabilities
          const spentDurabilities = {};
          Object.keys(exactDurabilities.current).forEach((key) => {
            const spent =
              initialDurabilities.current[key] - exactDurabilities.current[key];
            if (spent > 0 && config.tools[key]) {
              spentDurabilities[key] = Math.round(spent);
            }
          });

          formData.append("tools", JSON.stringify(spentDurabilities));

          // Step 1: Hash the image asynchronously
          const imageHash = await hashImage(blob); // Ensure this is awaited correctly

          // Step 2: Create the combined hash (colors, tools, intention, imageHash)
          const metaHash = await generateMetaHash(
            imageHash,
            spentQuantities,
            spentDurabilities,
            editorIntention,
            context
          );

          // Step 3: Append the meta hash and session key to the form data
          formData.append("meta_hash", metaHash);
          formData.append("session_key", sessionKey);

          // Send the form data to the backend
          const response = await axiosInstance.post(
            "/doodles/post/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Handle successful response
          onClose();
          const new_doodle = response.data;
          onDoodleSubmit(new_doodle, editorIntention, context, isDraft);
        } catch (error) {
          console.error("Error posting doodle:", error);
        } finally {
          setIsPosting(false); // Unlock the button and hide the loader after submission
        }
      },
      "image/webp",
      compressionLevel
    );
  };

  const handleCheckboxChange = (event) => {
    setShareOnWall(event.target.checked);
  };

  // Handle keyboard shortcuts for Undo and Redo
  useEffect(() => {
    const handleKeyDown = (e) => {
      // If the user is interacting (drawing), do not perform Undo/Redo
      if (isInteractingRef.current) return;

      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "z") {
        if (e.shiftKey) {
          // Redo
          handleRedo();
        } else {
          // Undo
          handleUndo();
        }
        e.preventDefault();
      } else if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "y") {
        // Redo
        handleRedo();
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleUndo, handleRedo]);

  return (
    <div className="editor-overlay">
      {loading ? (
        <Loader /> // Display the loader
      ) : (
        <div className="editor">
          <button className="close-button" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="fa-times" />
          </button>
          <div className="canvas-wrapper">
            <DrawingArea
              lines={lines}
              setLines={setLines}
              selectedTool={selectedTool}
              selectedColor={selectedColor}
              config={config}
              colorQuantities={colorQuantities}
              setColorQuantities={setColorQuantities}
              colorSpendFactor={colorSpendFactor}
              durabilitySpendFactor={durabilitySpendFactor}
              exactQuantities={exactQuantities}
              exactDurabilities={exactDurabilities}
              changedPixels={changedPixels}
              allChangedPixels={allChangedPixels}
              colorHistory={colorHistory}
              setColorHistory={setColorHistory}
              toolDurabilities={toolDurabilities}
              setToolDurabilities={setToolDurabilities}
              toolHistory={toolHistory}
              setToolHistory={setToolHistory}
              isDrawing={isDrawing}
              setIsDrawing={setIsDrawing}
              brushTextureSrc={brushTextureSrc}
              existingDoodleImage={existingDoodleImage}
              editorContext={editorContext}
              clearRedoStacks={clearRedoStacks} // Pass the function to clear redo stacks
            />
          </div>
          <div className="editor-toolbar">
            <div className="editing-buttons">
              <button onClick={handleUndo} disabled={lines.length === 0}>
                <FontAwesomeIcon icon={faRotateLeft} />
              </button>
              <button onClick={handleRedo} disabled={redoLines.length === 0}>
                <FontAwesomeIcon icon={faRotateRight} />
              </button>
              <button onClick={handleClear}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
            <ToolSelector
              selectedTool={selectedTool}
              onSelectTool={setSelectedTool}
              tools={config.tools}
              toolDurabilities={toolDurabilities}
            />
            <ColorPicker
              selectedColor={selectedColor}
              selectedColorLabel={selectedColorLabel}
              onSelectColor={handleColorSelect}
              colors={config.colors}
              hasPalette={config.tools[selectedTool].has_palette}
              colorQuantities={colorQuantities}
            />
            <div className="post-button">
              <button
                className="draft-button"
                onClick={() => handlePost(true)}
                disabled={isPosting}
              >
                {isPosting ? <Loader size="small" /> : "Save as Draft"}
              </button>
              <button onClick={handlePost} disabled={isPosting}>
                {isPosting ? <Loader size="small" /> : "Post"}
              </button>
            </div>
            {editorIntention === "drawReasonForMood" && (
              <div className="share-on-wall">
                <label>
                  <input
                    type="checkbox"
                    checked={shareOnWall}
                    onChange={handleCheckboxChange}
                  />
                  Share on Wall
                </label>
              </div>
            )}
          </div>
        </div>
      )}
      {/* Render the ConfirmationPrompt when isConfirmingClear is true */}
      {isConfirmingClear && (
        <ConfirmationPrompt
          title="Clear Canvas?"
          message="Are you sure you want to clear the canvas?"
          leftButton="Yes"
          rightButton="No"
          onConfirm={confirmClear}
          onCancel={cancelClear}
        />
      )}
      {isConfirmingClose && (
        <ConfirmationPrompt
          title="Close editor?"
          message="Are you sure?"
          leftButton="Yes"
          rightButton="No"
          onConfirm={confirmClose}
          onCancel={cancelClose}
        />
      )}
    </div>
  );
};

export default DoodleEditor;
