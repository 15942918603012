import React, { useEffect, useState, useCallback } from "react";
import { useDoodleContext } from "../contexts/DoodleContext";
import Doodle from "../components/Doodle";
import Loader from "../components/Loader";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";

const ProfileEntriesField = ({
  isViewingOwnProfile,
  handleOpenDoodleEditor,
}) => {
  const {
    usernameForProfilePage,
    isProfileEntriesFeedOpen,
    profileEntriesFeed,
    setProfileEntriesFeed,
    profileEntryTypeObject,
  } = useDoodleContext();

  const initialUrl = `/profile/${usernameForProfilePage}/${profileEntryTypeObject.id}`;
  const { items, setItems, total, loading, error, loadMore, hasMore, setUrl } =
    usePagination(initialUrl, 10);

  const [isDebouncing, setIsDebouncing] = useState(false);

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing && hasMore && !loading) {
      loadMore();
      setIsDebouncing(true);
      setTimeout(() => {
        setIsDebouncing(false);
      }, 100);
    }
  }, [isDebouncing, hasMore, loading, loadMore]);

  useEffect(() => {
    if (isProfileEntriesFeedOpen && profileEntryTypeObject) {
      setUrl(`/profile/${usernameForProfilePage}/${profileEntryTypeObject.id}`);
      setItems([]);
    }
  }, [
    isProfileEntriesFeedOpen,
    profileEntryTypeObject,
    usernameForProfilePage,
    setUrl,
    setItems,
  ]);

  // Intersection observer to trigger loading more items
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  useEffect(() => {
    if (inView && hasMore && !loading) {
      debouncedLoadMore();
    }
  }, [inView, hasMore, loading, debouncedLoadMore]);

  useEffect(() => {
    setProfileEntriesFeed(items);
  }, [items, setProfileEntriesFeed]);

  return (
    <>
      <div className="feed">
        {/* Profile Entries Feed */}
        {profileEntriesFeed.map((doodle) => (
          <div key={doodle.id} className="post">
            <Doodle
              doodle={doodle}
              typeOfDoodle={"profileEntries"}
              isViewingOwnWall={isViewingOwnProfile}
              reactionsButtonDisabled={true}
              isSuitableForAvatar={profileEntryTypeObject.is_avatars}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
            />
          </div>
        ))}
        {loading && <Loader />}
        {!loading && !hasMore && profileEntriesFeed.length === 0 && (
          <p>Nothing here yet.</p>
        )}
        {error && <p>{error}</p>}
        <div ref={ref}></div> {/* Intersection observer target */}
      </div>
    </>
  );
};

export default ProfileEntriesField;
