import React, { useContext } from "react";
import { ModalContext } from "../contexts/ModalContext";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { AxiosContext } from "../contexts/AxiosContext";
import { useDoodleContext } from "../contexts/DoodleContext";

const Delete = ({ doodle, typeOfDoodle }) => {
  const { showModal, hideModal } = useContext(ModalContext);
  const axiosInstance = useContext(AxiosContext);
  const { onDoodleDelete } = useDoodleContext();

  const performDelete = async () => {
    try {
      await axiosInstance.post(`/doodles/${doodle.id}/delete/`);
      onDoodleDelete(doodle, typeOfDoodle);
    } catch (error) {
      console.error("Error deleting post:", error);
    } finally {
      hideModal();
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling if necessary
    showModal(
      <ConfirmationPrompt
        title="Confirm Deletion"
        message="Are you sure you want to delete this?"
        leftButton="Yes"
        rightButton="No"
        onConfirm={performDelete}
        onCancel={hideModal}
      />
    );
  };

  return (
    <div className="menu-item danger" onClick={handleDeleteClick}>
      Delete
    </div>
  );
};

export default Delete;
